<template>
  <div class="home-page">
    <h1 class="main-title">Deja tu reseña de tu academia o curso</h1>

    
    <div class="category-section">
      <h2 class="section-title">Academias</h2>
      <div class="card-container">
        <div
          v-for="academia in academias"
          :key="academia.user_id"
          class="card"
          @click="redirectToReview('academy', academia.user_username)"
        >
          <img
            :src="academia.logoUrl"
            alt="Academia Logo"
            class="card-logo"
          />
          <h3 class="card-title">{{ academia.name }}</h3>
          <!-- <p class="card-url">{{ academia.email }}</p> -->
          <div class="rating">
            <span
              v-for="star in 5"
              :key="star"
              :class="{ 'star-filled': star <= academia.rating }"
              class="star"
            >★</span>
            <span class="rating-score">{{ academia.rating }}</span>
          </div>
        </div>
      </div>
      <!-- <button class="view-more-button">Ver más</button> -->
    </div>

    
    <!-- <div class="category-section">
      <h2 class="section-title">Cursos</h2>
      <div class="card-container">
        <div
          v-for="curso in cursos"
          :key="curso.course_id"
          class="card"
           @click="redirectToReview('course', curso.course_id)"
        >
          <img
             :src="curso.logoUrl"
            alt="Curso Logo"
            class="card-logo"
          />
          <h3 class="card-title">{{ curso.name }}</h3>
          
          <div class="rating">
            <span
              v-for="star in 5"
              :key="star"
              :class="{ 'star-filled': star <= curso.rating }"
              class="star"
            >★</span>
            <span class="rating-score">{{ curso.rating }}</span>
          </div>
        </div>
      </div>      
    </div> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomePage",
  data() {
    return {
      academias: [],
      cursos: [],
    };
  },
  methods: {
    async fetchAcademias() {
      try {
        const response = await axios.get("/api/academias");
        this.academias = response.data.data.map((academia) => ({
          user_id: academia.user_id,
          user_username: academia.user_username,          
          name: `${academia.user_first_name} ${academia.user_last_name}`,
          email: academia.user_email,
          // rating: 4, // Valor fijo
          logoUrl: `https://mentora.net/image/show/70/${academia.user_id}/MEDIUM`, // Construir URL de la imagen
        }));
        console.log(this.academias);
      } catch (error) {
        console.error("Error fetching academias:", error);
      }
    },
    async fetchCursos() {
      try {
        const response = await axios.get("/api/cursos");
        this.cursos = response.data.data.map((curso) => ({
          course_id: curso.course_id,
          name: curso.course_slug.replace(/_/g, " "),
          url: curso.course_host_url || "URL no disponible",
          rating: curso.course_ratings,
          logoUrl: `https://mentora.net/image/show/57/${curso.course_id}/MEDIUM`, // Construir URL de la imagen
        }));
      } catch (error) {
        console.error("Error fetching cursos:", error);
      }
    },
    redirectToReview(type, user_username) {
      this.$router.push({ name: "ReviewPage", params: { type, user_username } });
    },
  },
  mounted() {
    this.fetchAcademias();
    this.fetchCursos();
  },
};
</script>

<style scoped lang="scss">
.home-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;

  .main-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #2c3e50;
    font-weight: bold;
  }

  .category-section {
    margin-top: 40px;

    .section-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #2c3e50;
    }

    .card-container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;

      .card {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 16px;
        padding: 0 0 15px 0;
        width: 95vw;
        max-width: 275px;
        text-align: left;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out;
        cursor: pointer;
        position: relative;
        padding-bottom: 40px;
        overflow: hidden;
        &:hover {
          transform: translateY(-5px);
        }

        .card-logo {
          width: 100%;
          max-height: 154px;
          object-fit: cover;
          margin-bottom: 10px;
        }

        .card-title {
          font-size: 14px;
          font-weight: bold;
          color: #2c3e50;
          margin-bottom: 5px;
          padding: 0 15px;
        }

        .card-url {
          font-size: 14px;
          color: #999;
          margin-bottom: 10px;
        }

        .rating {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 12px;
          left: 15px;
          .star {
            font-size: 18px;
            color: #ccc;
            &.star-filled {
              color: #f0c420;
            }
          }

          .rating-score {
            font-size: 14px;
            font-weight: bold;
            color: #2c3e50;
            margin-left: 5px;
          }
        }
      }
    }

    .view-more-button {
      margin-top: 15px;
      background-color: transparent;
      border: 2px solid #2c3e50;
      color: #2c3e50;
      padding: 5px 15px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #2c3e50;
        color: #fff;
      }
    }
  }
}
</style>
