<template>
    <div class="modal-overlay" v-if="isVisible">
      <div class="modal-container">
        <div class="modal-header">
          <h2>Valora tu experiencia reciente</h2>
          <button class="close-button" @click="closeModal">✕</button>
        </div>
  
        <div class="modal-body">
          <!-- Select para Academia o Curso -->
          <div v-if="courses.length > 0" class="select-container">
            <label for="review-type">Selecciona Academia o Curso</label>
            <select v-model="selectedTarget" id="review-type">
              <option :value="null">Academia: {{ titleName }}</option>
              <option v-for="course in courses" :key="course.course_id" :value="course.course_id">
                Curso: {{ course.course_slug.replace(/_/g, ' ') }}
              </option>
            </select>
          </div>
  
          <!-- Sección de estrellas -->
          <div class="rating-section">
            <span
              v-for="star in 5"
              :key="star"
              :class="['star', { 'star-filled': star <= rating }]"
              @click="updateRating(star)"
            >
              ★
            </span>
          </div>
            <!-- Título para la opinión -->
            <div class="title-section">
            <label for="title" class="section-label">Ponle título a tu opinión</label>
            <input
              id="title"
              type="text"
              v-model="title"
              placeholder="¿Qué aspectos merecen formar parte del título?"
            />
          </div>
          <br>
          <!-- Textarea para opinión -->
          <div class="opinion-section">
            <label for="opinion" class="section-label">Cuéntanos más sobre tu experiencia...</label>
            <textarea
              id="opinion"
              v-model="opinion"
              placeholder="¿Por qué fue buena tu experiencia? ¿En qué destaca positivamente esta empresa? Recuerda valorar de forma honesta y constructiva."
            ></textarea>
            <small>
              <a href="#">Cómo escribir opiniones útiles</a>
            </small>
          </div>
  
         
        </div>
  
        <div class="modal-footer">
          <button class="submit-button" @click="submitReview">Enviar opinión</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ModalComponent",
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
      selectedStars: {
        type: Number,
        required: true,
      },
      courses: {
        type: Array,
        required: true,
      },
      titleName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        opinion: "",
        title: "",
        rating: this.selectedStars,
        selectedTarget: null, // Por defecto, seleccionado como academia
      };
    },
    watch: {
      selectedStars(newVal) {
        this.rating = newVal;
      },
    },
    methods: {
      updateRating(star) {
        this.rating = star;
        this.$emit("update:selectedStars", star);
        console.log(this.courses)
      },
      closeModal() {
        this.$emit("close");
      },
      submitReview() {
        if (!this.rating || !this.opinion || !this.title) {
          alert("Por favor, completa todos los campos antes de enviar tu opinión.");
          return;
        }
  
        const reviewData = {
          rating: this.rating,
          opinion: this.opinion,
          title: this.title,
          target: this.selectedTarget, // Puede ser null (Academia) o ID del curso
        };
  
        this.$emit("submit", reviewData);
        this.closeModal();
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    border-radius: 10px;
    width: 400px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  
    h2 {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      color: #333;
    }
  
    .close-button {
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
      color: #999;
  
      &:hover {
        color: #333;
      }
    }
  }
  
  .rating-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .star {
      font-size: 30px;
      color: #ccc;
      cursor: pointer;
  
      &.star-filled {
        color: #f0c420;
      }
  
      &:hover {
        transform: scale(1.2);
      }
  
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  
  .section-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
    color: #333;
  }
  
  .opinion-section {
    textarea {
      width: 100%;
      height: 80px;
      margin-top: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      resize: none;
    }
  
    small {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      color: #0073e6;
  
      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  
  .title-section {
    input {
      width: 100%;
      margin-top: 5px;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
    }
}
select {
  width: 100%;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  
    .submit-button {
      background: #28a745;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;
  
      &:hover {
        background: #218838;
      }
    }
  }
  </style>
  