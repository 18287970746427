<template>
  <div class="reviews-container">
    <!-- Bloque de Reviews de Academias -->
    <section v-if="reviews.academias.length">
      <h2>Reviews de Academias</h2>
      <div v-for="(item, index) in reviews.academias" :key="'academy-' + index" class="review-card">
        <!-- Título y Fecha -->
        <div class="review-header">
          <div class="review-title">{{ item.details.user_first_name }} {{ item.details.user_last_name }}</div>
          <div class="review-date">{{ formatDate(item.review.created_at) }}</div>
        </div>
        <!-- Título y Opinión -->
        <div class="review-body">
          <div class="review-content">
            <strong>{{ item.review.title }}</strong>
            <p>{{ item.review.opinion }}</p>
          </div>
          <!-- Botones de acción -->
          <div class="review-actions">
            <button @click="approveReview(item.review.id_review)">✔</button>
            <button @click="deleteReview(item.review.id_review)">✘</button>
          </div>
        </div>
      </div>
    </section>

    <!-- Bloque de Reviews de Cursos -->
    <section v-if="reviews.cursos.length">
      <h2>Reviews de Cursos</h2>
      <div v-for="(item, index) in reviews.cursos" :key="'course-' + index" class="review-card">
        <!-- Título y Fecha -->
        <div class="review-header">
          <div class="review-title">{{ item.details.course_slug }}</div>
          <div class="review-date">{{ formatDate(item.review.created_at) }}</div>
        </div>
        <!-- Título y Opinión -->
        <div class="review-body">
          <div class="review-content">
            <strong>{{ item.review.title }}</strong>
            <p>{{ item.review.opinion }}</p>
          </div>
          <!-- Botones de acción -->
          <div class="review-actions">
            <button @click="approveReview(item.review.id_review)">✔</button>
            <button @click="deleteReview(item.review.id_review)">✘</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ReviewsManager",
  data() {
    return {
      reviews: {
        academias: [],
        cursos: [],
      },
    };
  },
  methods: {
    async fetchPendingReviews() {
      try {
        const response = await axios.get("/api/reviews/pending");
        this.reviews = response.data; // Asignar los datos recibidos
      } catch (error) {
        console.error("Error fetching pending reviews:", error);
      }
    },
    async approveReview(reviewId) {
    try {
      const response = await axios.post(`/api/reviews/approve/${reviewId}`);
      console.log(response.data.message); // Mensaje de éxito

      // Filtrar el review aprobado del listado
      this.reviews.academias = this.reviews.academias.filter(
        (item) => item.review.id_review !== reviewId
      );

      this.reviews.cursos = this.reviews.cursos.filter(
        (item) => item.review.id_review !== reviewId
      );

      // También actualizar `filteredReviews` si estás aplicando filtros
    } catch (error) {
      console.error("Error approving review:", error);
    }
  },

  async deleteReview(reviewId) {
    try {
      const response = await axios.post(`/api/reviews/delete/${reviewId}`);
      console.log(response.data.message); // Mensaje de éxito

      // Filtrar el review eliminado del listado
      this.reviews.academias = this.reviews.academias.filter(
        (item) => item.review.id_review !== reviewId
      );

      this.reviews.cursos = this.reviews.cursos.filter(
        (item) => item.review.id_review !== reviewId
      );

      // También actualizar `filteredReviews` si estás aplicando filtros
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  },

    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("es-ES", options);
    },
  },
  mounted() {
    this.fetchPendingReviews(); // Cargar las reseñas al montar el componente
  },
};
</script>

<style scoped>
.reviews-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}
section {
  padding-top: 20px;

}
h2 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #333;
}

.review-card {
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-bottom: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.review-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.review-title {
  font-weight: bold;
}

.review-date {
  font-size: 12px;
  color: #999;
}

.review-body {
  display: flex;
  justify-content: space-between;
}

.review-content {
  margin-top: 10px;
  flex: 0 0 90%;
  text-align: left;
}

.review-actions {
  flex: 0 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.review-actions button {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.review-actions button:hover {
  background-color: #f0f0f0;
}

.review-actions button:first-child {
  color: green;
  border-color: green;
}

.review-actions button:last-child {
  color: red;
  border-color: red;
}
</style>
