<template>
    <div>
      <!-- Barra de navegación superior -->
      <nav class="navbar">
        <button @click="toggleMenu" class="menu-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 11.6667H4M12 5H4M20 18.3333H4" stroke="#131214" stroke-width="1.5" stroke-linecap="round"></path>
                        </svg>
        </button>
        <div class="logo">
           <svg width="138" height="18" viewBox="0 0 138 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_477_259)">
                      <path d="M0 15.2288V2.56085C0 2.1203 0.258317 1.87018 0.713307 1.87018H3.60763C3.98337 1.87018 4.26223 2.00376 4.4589 2.33062L8.02838 7.99239L11.5978 2.33062C11.7945 2.00376 12.0734 1.87018 12.4491 1.87018H15.3229C15.7984 1.87018 16.0362 2.1203 16.0362 2.56085V15.2288C16.0362 15.6693 15.7984 15.9194 15.3229 15.9194H12.9247C12.4491 15.9194 12.2114 15.6693 12.2114 15.2288V7.55185L8.87965 13.0999C8.68297 13.4069 8.50391 13.5604 8.12525 13.5604H7.90802C7.5499 13.5405 7.35323 13.387 7.17417 13.0999L3.82485 7.495V15.2316C3.82485 15.6722 3.56654 15.9223 3.11155 15.9223H0.713307C0.258317 15.9223 0 15.6722 0 15.2316V15.2288Z" fill="#183404"/>
                      <path d="M17.9619 10.4879C17.9619 6.74463 21.0147 4.82611 23.8679 4.82611C27.3376 4.82611 29.7153 6.99475 29.7153 10.7579V10.9881C29.7153 11.4287 29.4188 11.6589 28.9814 11.6589H21.7251C22.0039 12.5229 22.9932 13.2704 24.6781 13.2704C25.5499 13.2704 26.2838 12.9834 26.7006 12.7333C27.0969 12.5229 27.4139 12.4831 27.7309 12.81L28.6615 13.6343C28.999 13.9412 28.958 14.4017 28.6409 14.6887C27.9276 15.2828 26.5186 16.1298 24.3787 16.1298C20.3337 16.1298 17.956 13.8275 17.956 10.485L17.9619 10.4879ZM21.6898 9.62384H25.9521C25.8728 8.6461 25.2388 7.74226 23.8503 7.74226C22.4619 7.74226 21.7486 8.64326 21.6898 9.62384Z" fill="#183404"/>
                      <path d="M31.5235 15.2288V5.72714C31.5235 5.28659 31.7818 5.03647 32.2368 5.03647H34.4971C34.9521 5.03647 35.2104 5.2468 35.2104 5.6504V6.57129C36.0822 5.6504 37.3327 4.8233 38.9971 4.8233C41.3953 4.8233 43.0802 6.55139 43.0802 9.54429V15.226C43.0802 15.6665 42.8219 15.9166 42.3669 15.9166H40.1067C39.6517 15.9166 39.3934 15.6665 39.3934 15.226V10.0047C39.3934 8.75698 38.5597 8.02652 37.3709 8.02652C36.182 8.02652 35.2104 8.81383 35.2104 10.0047V15.226C35.2104 15.6665 34.9521 15.9166 34.4971 15.9166H32.2368C31.7818 15.9166 31.5235 15.6665 31.5235 15.226V15.2288Z" fill="#183404"/>
                      <path d="M46.1125 13.0602V8.20275H45.6575C45.2026 8.20275 44.9648 7.97253 44.9648 7.54903V7.12553C44.9648 6.79867 45.0851 6.56845 45.3405 6.30128L48.9687 2.46139C49.3855 2.00095 49.82 2.23117 49.82 2.59498V5.03363H51.3669C51.8219 5.03363 52.0802 5.28375 52.0802 5.7243V7.50924C52.0802 7.96968 51.8219 8.19991 51.3669 8.19991H49.82V11.8863C49.82 12.9806 50.5127 13.2477 51.5636 13.1341C51.9599 13.0971 52.1977 13.2307 52.2769 13.6712L52.4941 15.0526C52.5939 15.4761 52.4354 15.7631 51.9981 15.8768C51.5431 15.9934 50.8092 16.127 49.817 16.127C47.4981 16.127 46.1096 15.1094 46.1096 13.0545L46.1125 13.0602Z" fill="#183404"/>
                      <path d="M53.6859 10.468C53.6859 7.37849 56.2632 4.8233 59.8121 4.8233C63.3611 4.8233 65.9178 7.37565 65.9178 10.468C65.9178 13.5604 63.32 16.1298 59.8121 16.1298C56.3043 16.1298 53.6859 13.5774 53.6859 10.468ZM57.3934 10.468C57.3934 11.8494 58.2857 12.9436 59.8121 12.9436C61.3386 12.9436 62.2309 11.8494 62.2309 10.468C62.2309 9.08669 61.318 8.01231 59.8121 8.01231C58.3063 8.01231 57.3934 9.06679 57.3934 10.468Z" fill="#183404"/>
                      <path d="M67.7231 15.2288V5.72711C67.7231 5.28656 67.9814 5.03644 68.4364 5.03644H70.6967C71.1517 5.03644 71.41 5.28656 71.41 5.72711V7.01465C72.0059 6.1705 72.9569 5.18992 74.6213 4.9796C75.138 4.90286 75.4139 5.18992 75.4139 5.65037V7.93554C75.4139 8.35904 75.1967 8.666 74.68 8.666C73.0744 8.666 71.41 9.33677 71.41 11.8522V15.2316C71.41 15.6722 71.1517 15.9223 70.6967 15.9223H68.4364C67.9814 15.9223 67.7231 15.6722 67.7231 15.2316V15.2288Z" fill="#183404"/>
                      <path d="M83.683 15.422L83.6624 14.8451C83.1458 15.5158 82.0362 16.1127 80.4511 16.1127C77.9736 16.1127 76.2094 14.9616 76.2094 12.7532C76.2094 10.3344 78.6869 9.33677 80.6889 9.33677C81.6194 9.33677 82.6116 9.4533 83.545 9.74037C83.5832 8.28229 82.6321 7.78206 81.364 7.78206C80.1957 7.78206 79.0245 8.03217 78.2319 8.29935C77.7769 8.43293 77.4599 8.31924 77.3014 7.8588L76.9051 6.61105C76.7671 6.20745 76.9051 5.88059 77.3425 5.71005C78.3934 5.28656 80.0372 4.82611 81.9423 4.82611C85.8082 4.82611 87.2349 6.61105 87.2349 9.91375V15.2117C87.2349 15.6722 86.9765 15.9223 86.501 15.9223H84.3992C83.9442 15.9223 83.6859 15.7489 83.6859 15.422H83.683ZM83.545 12.1591V11.6418C83.0108 11.4315 82.4149 11.315 81.7808 11.315C80.6918 11.315 79.8787 11.6987 79.8787 12.449C79.8787 13.2562 80.6331 13.657 81.5842 13.657C82.6938 13.657 83.548 13.1568 83.548 12.1591H83.545Z" fill="#183404"/>
                      <path d="M134.574 0H93.4814C91.5895 0 90.0558 1.48503 90.0558 3.31691V14.6831C90.0558 16.515 91.5895 18 93.4814 18H134.574C136.466 18 138 16.515 138 14.6831V3.31691C138 1.48503 136.466 0 134.574 0Z" fill="#A0EC74"/>
                      <path d="M93.3376 14.072C93.3376 13.0346 94.23 12.1705 95.3395 12.1705C96.4491 12.1705 97.3415 13.0346 97.3415 14.072C97.3415 15.1094 96.4491 15.9735 95.3395 15.9735C94.23 15.9735 93.3376 15.1293 93.3376 14.072Z" fill="white"/>
                      <path d="M100.729 15.0497V5.54806C100.729 5.10751 100.987 4.8574 101.442 4.8574H103.703C104.158 4.8574 104.416 5.06772 104.416 5.47132V6.39221C105.288 5.47132 106.538 4.64423 108.203 4.64423C110.601 4.64423 112.286 6.37232 112.286 9.36521V15.0469C112.286 15.4874 112.027 15.7375 111.572 15.7375H109.312C108.857 15.7375 108.599 15.4874 108.599 15.0469V9.82566C108.599 8.57791 107.765 7.84745 106.576 7.84745C105.387 7.84745 104.416 8.63475 104.416 9.82566V15.0469C104.416 15.4874 104.158 15.7375 103.703 15.7375H101.442C100.987 15.7375 100.729 15.4874 100.729 15.0469V15.0497Z" fill="#183404"/>
                      <path d="M114.091 10.3089C114.091 6.56562 117.144 4.64709 119.997 4.64709C123.467 4.64709 125.844 6.81574 125.844 10.5789V10.8091C125.844 11.2497 125.548 11.4799 125.111 11.4799H117.854C118.133 12.3439 119.122 13.0914 120.807 13.0914C121.679 13.0914 122.413 12.8044 122.83 12.5542C123.226 12.3439 123.543 12.3041 123.86 12.631L124.791 13.4552C125.128 13.7622 125.087 14.2226 124.77 14.5097C124.057 15.1037 122.648 15.9507 120.508 15.9507C116.463 15.9507 114.085 13.6485 114.085 10.306L114.091 10.3089ZM117.819 9.44482H122.081C122.002 8.46708 121.368 7.56325 119.979 7.56325C118.591 7.56325 117.878 8.46424 117.819 9.44482Z" fill="#183404"/>
                      <path d="M128.307 12.8811V8.02367H127.852C127.397 8.02367 127.16 7.79345 127.16 7.36995V6.94646C127.16 6.6196 127.28 6.38937 127.535 6.1222L131.163 2.28232C131.58 1.82187 132.015 2.05209 132.015 2.4159V4.85456H133.562C134.017 4.85456 134.275 5.10468 134.275 5.54522V7.33016C134.275 7.79061 134.017 8.02083 133.562 8.02083H132.015V11.7072C132.015 12.8015 132.707 13.0687 133.758 12.955C134.155 12.918 134.392 13.0516 134.472 13.4922L134.689 14.8735C134.789 15.297 134.63 15.5841 134.193 15.6978C133.738 15.8143 133.004 15.9479 132.012 15.9479C129.693 15.9479 128.304 14.9304 128.304 12.8754L128.307 12.8811Z" fill="#183404"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_477_259">
                      <rect width="138" height="18" fill="white"/>
                      </clipPath>
                      </defs>
                  </svg>
        </div>
        <button class="search-icon">
          <!-- Ícono de búsqueda -->
          🔍
        </button>
      </nav>
  
      <!-- Fondo oscuro cuando el menú está abierto -->
      <div v-if="isMenuOpen" class="overlay" @click="toggleMenu"></div>
  
      <!-- Menú lateral -->
      <aside :class="['side-menu', { 'side-menu--open': isMenuOpen }]">
        <button class="close-button" @click="toggleMenu">✕</button>
        <ul>
          <!-- <li><a href="#" class="menu-link">Iniciar sesión</a></li> -->
          <!-- <li><a href="#" class="menu-link">Regístrate</a></li> -->
          <!-- <hr /> -->
          <li><a href="#" class="menu-link">Mentora.net</a></li>
          <li><a href="#" class="menu-link">Cursos</a></li>
          <li><a href="#" class="menu-link">Academias</a></li>
        </ul>

      </aside>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Barra de navegación superior */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
  }
  
  /* Ícono de menú de tres líneas */
  .menu-icon {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
  }
  
  .menu-bar {
    width: 20px;
    height: 2px;
    background-color: black;
  }
  
  /* Logo */
  .logo {
    font-size: 20px;
    font-weight: bold;
  }
  
  .highlight {
    background-color: #a0e66a;
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  /* Ícono de búsqueda */
  .search-icon {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    opacity: 0;
  }
  
  /* Fondo oscuro */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
  }
  
  /* Menú lateral */
  .side-menu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    z-index: 999;
    transition: left 0.3s ease;
  }
  
  .side-menu--open {
    left: 0;
  }
  
  .close-button {
    align-self: flex-end;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Estilos de enlaces en el menú */
  .menu-link {
    display: block;
    padding: 10px 0;
    color: #183404;
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
    text-align: left;
  }
  
  .menu-link:hover {
    color: #007d32;
  }
  
  /* Selector de idioma */
  .language-select {
    margin-top: 20px;
    padding: 8px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  li {
    list-style: none;
  }
  </style>
  