<template>
    <div class="review-page">
      <!-- Fragmento 1: Encabezado -->
      <section class="header-section">
        <img :src="imageUrl" alt="Logo" class="header-logo" />
        <div class="header-details">
          <h1>{{ details.name }}</h1>
          <p class="header-type"><b>{{ titleName }}</b></p>
          <p class="header-rating">Calificación promedio: {{ rating }}</p>
        </div>
      </section>
  
      <!-- Fragmento 2: Selector de estrellas -->
      <section class="rating-section">
        <h2>Selecciona tu calificación</h2>
        <div class="stars-container">
          <span
            v-for="star in 5"
            :key="star"
            :class="{ 'star-filled': star <= selectedStars }"
            class="star"
            @click="handleOpenModal(star)"
          >
            ★
          </span>
        </div>
      </section>
  
      <!-- Modal para calificar -->
      <ModalComponent
        :isVisible="isModalVisible"
        v-model:selectedStars="selectedStars"
        :courses="courses"
        :titleName="titleName"
        @close="closeModal"
        @submit="handleReviewSubmit"
        :type=typePage
        />

  
      <!-- Fragmento 3: Comentarios -->
      <section class="comments-section">
        <h2>Opiniones</h2>
        <div class="filters">
          <button
            v-for="filter in filters"
            :key="filter"
            :class="{ active: selectedFilter === filter }"
            @click="applyFilter(filter)"
          >
            {{ filter }} estrellas
          </button>
        </div>
        <div class="comments-container">
            <div class="comments-container">
        <div v-for="comment in filteredReviews" :key="comment.id_review" class="comment-card">
          <!-- Fecha en la esquina superior derecha -->
          <div class="comment-header">
            <div class="rating">
                <span
                v-for="star in 5"
                :key="star"
                :class="{ 'star-filled': star <= comment.rating }"
                class="star"
                >
                ★
                </span>
            </div>
            <span class="comment-date">{{ formatDate(comment.created_at) }}</span>
          </div>
          <!-- Título -->
          <h3 class="comment-title">{{ comment.title }}</h3>
          <!-- Estrellas -->
          
          <!-- Comentario -->
          <p class="comment-text">{{ comment.opinion }}</p>
        </div>
      </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import ModalComponent from "./ModalComponent.vue";
  
  export default {
    name: "ReviewPage",
    components: { ModalComponent },
    props: ["type", "user_username"],
    data() {
      return {
        details: {},
        imageUrl: "",
        reviews: [],
        filteredReviews: [],
        courses: [],
        selectedFilter: null,
        selectedStars: 0,
        isModalVisible: false,
        filters: [5, 4, 3, 2, 1],
        titleName: '',
        rating: "0.0"
      };
    },
    computed: {
    //   titleName() {
    //     return this.type === "course" ? "Curso" : "Academia";
    //   },
    },
    methods: {
      async fetchDetails() {
        const endpoint =
          this.type === "course"
            ? `/api/curso/${this.user_username}`
            : `/api/academia/${this.user_username}`;
        try {
          const response = await axios.get(endpoint);
          if(this.type != "course") {
              this.details = response.data.data.academy;
              this.courses = response.data.data.courses;
              console.log(this.courses)
            } else {
                this.details = response.data.data;
                
          }
          console.log(this.details)
          if(this.type == 'course'){
            // como curso
            this.titleName = `${this.details.course_slug.replace(/_/g, ' ')}`;
            const rawRating = parseFloat(this.details.course_ratings); // Convertimos a número
            this.rating = (isNaN(rawRating) ? 0 : rawRating.toFixed(1)); // Redondeamos y convertimos a string

          }else {
            // como amademia
            this.titleName = `${this.details.user_first_name} ${this.details.user_last_name}`;
          }
          this.imageUrl = `https://mentora.net/image/show/${
            this.type === "course" ? 57 : 70 }/${this.details.user_id}`;
        } catch (error) {
          console.error("Error fetching details:", error);
        }
      },
      async fetchReviews() {
        try {
            const response = await axios.get(
                `/api/reviews/${this.type === "course" ? 57 : 70}/${this.user_username}`
            );
            // console.log(response.data); // Debug
            this.reviews = response.data; // Asigna directamente los datos
            this.filteredReviews = this.reviews; // Filtra inicialmente sin cambios
            
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    },


    async applyFilter(filter) {
        // Si el filtro seleccionado es el mismo que el actual, lo desactivamos
        if (this.selectedFilter === filter) {
            this.selectedFilter = null; // Desactivamos el filtro
            // Realizamos una petición para obtener todas las reseñas sin filtro
            await this.fetchReviews(); // Vuelve a cargar todas las reseñas
        } else {
            // Si es un filtro diferente, aplicamos el filtro normalmente
            this.selectedFilter = filter;
            try {
                const response = await axios.post(
                    `/api/reviews/${this.type === "course" ? 57 : 70}/${this.user_username}`,
                    { rating: filter } // Enviar el rating como parámetro
                );
                this.filteredReviews = response.data; // Asignar las reseñas filtradas
            } catch (error) {
                console.error("Error fetching filtered reviews:", error);
            }
        }
    },

      handleOpenModal(stars) {
        this.selectedStars = stars;
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
        this.selectedStars = 0;
      },
      formatDate(date) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(date).toLocaleDateString("es-ES", options);
        },
        async handleReviewSubmit(data) {
            const payload = {
                type: data.target ? "course" : "academy",
                id: data.target || this.details.user_id, // Si hay target (curso), usa su ID; de lo contrario, usa ID de la academia
                rating: this.selectedStars,
                title: data.title,
                opinion: data.opinion,
            };

            try {
                await axios.post("/api/reviews", payload);
                this.closeModal();
                this.fetchReviews();
            } catch (error) {
                console.error("Error submitting review:", error);
            }
        }

    },
    mounted() {
      this.fetchDetails();
      this.fetchReviews();
    },
  };
  </script>
  
  <style scoped>
  /* Encabezado */
  .header-section {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 62px auto 20px;    
    justify-content: center;
    flex-direction: column;
  }
  .header-logo {
    max-width: 300px;
    
    object-fit: contain;
    border-radius: 15px;
  }
  .header-details {
    text-align: left;
  }
  
  /* Estrellas */
  .rating-section {
    margin-bottom: 20px;
  }
  .stars-container {
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  .star {
    font-size: 24px;
    cursor: pointer;
    color: #ccc;
  }
  .star-filled {
    color: #f0c420;
  }
  
  /* Comentarios */
  .comments-section {
    margin-top: 20px;
  }
  .filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  .filters button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
  }
  .filters button.active {
    background-color: #183404;
    color: white;
  }
  .comment-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
  }
  .comment-author {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .comments-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 800px;
  width: 90vw;
  margin: 0 auto;
}
.comment-card {
  border: 1px solid #ddd;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.comment-header {
  display: flex;
  font-size: 12px;
  color: #999;
  align-items: center;
  justify-content: space-between
}
.comment-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}
.rating {
  display: flex;
}
.comment-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
.comments-section {
  margin-top: 20px;
}
.header-type {
    font-size: 20px;
    margin-bottom: 20px;
}
  </style>
  